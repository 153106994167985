import { getValueStorage } from "@/utils/storage";
import { Navigate } from "react-router-dom";

const withProtectedRoute = (WrappedComponent: any) => {
  const ProtectedRoute = (props: any) => {
    const getToken = getValueStorage("alphacorp::token");

    return getToken ? (
      <WrappedComponent {...props} />
    ) : (
      <Navigate
        to={"/auth/login"}
        replace
      />
    );
  };

  return ProtectedRoute;
};

export default withProtectedRoute;
