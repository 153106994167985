import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box, Button, Tooltip, useMediaQuery } from "@mui/material";
import { Send, Language } from "@mui/icons-material"; // Import Language icon
import { useState } from "react";

interface IBootstrapInput {
  width?: number | string;
  style?: React.CSSProperties;
}

const BootstrapInput = styled(InputBase)<IBootstrapInput>(
  ({ theme, width = "100%", style }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#1A2027",
      border: "1px solid",
      borderColor: "#2D3843",
      fontSize: 16,
      width: width,
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...style,
    },
  })
);

interface IChatInput {
  placeholder?: string;
  id: string;
  value?: string;
  onChangeValue?: (value: string) => void;
  isDisabled?: boolean;
  onEnter?: (value: string, isWebSearch: boolean) => void;
  style?: React.CSSProperties;
}


const ChatInput = ({
  placeholder = "",
  id,
  value,
  onChangeValue,
  isDisabled,
  onEnter,
  style,
}: IChatInput) => {
  const isMobile = useMediaQuery("(min-width: 600px)");
  const [content, setContent] = useState<string>(value || "");  // Initialize with value if needed

  const handleEnterMsg = (isWebSearch: boolean = false) => {
    onEnter?.(content, isWebSearch); // Pass isWebSearch to the onEnter callback
    setContent("");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      <BootstrapInput
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onChange={(e) => setContent(e.target.value)} // Update content directly
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.shiftKey) {
            setContent(content + "\n"); // Appends a newline on Shift+Enter
          }
          if (e.key === "Enter" && !e.shiftKey && content.trim() !== "") {
            handleEnterMsg(); // Send message on Enter
            e.preventDefault(); // Prevent form submit
          }
        }}
        disabled={isDisabled}
        value={content}
        style={{
          ...style,
          ...(isMobile ? { fontSize: "0.75rem" } : {}),
          backgroundColor: "#343541",
        }}
        multiline
        maxRows={4}
      />
      <Tooltip title="Send Message" placement="top">
        <Button
          variant="text"
          onClick={() => handleEnterMsg()}
          disabled={isDisabled || content.trim() === ""}
        >
          <Send style={{ color: content.trim() === "" ? "#FFFFFF50" : "white" }} />
        </Button>
      </Tooltip>

      <Tooltip title="Search the Web" placement="top">
        <Button
          variant="text"
          onClick={() => handleEnterMsg(true)}
          disabled={isDisabled || content.trim() === ""}
        >
          <Language style={{ color: content.trim() === "" ? "#FFFFFF50" : "white" }} />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default ChatInput;

