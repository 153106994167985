import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { RouterProvider } from "react-router-dom";
import { routes } from "@/configs/router";
import { ThemeProvider } from "@emotion/react";
import primaryTheme from "@/configs/theme";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <ThemeProvider theme={primaryTheme}>
      <RouterProvider router={routes} />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
