import { HttpStatus } from "@/constants/enums";
import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import LogoImg from "@/assets/ICMainLogo.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema, loginSchema } from "@/schemas/user";
import { LoadingButton } from "@mui/lab";
import { AuthService } from "@/services/apis";
import { getValueStorage, setOnStorage } from "@/utils/storage";
import { showError } from "@/utils/helper";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "@/configs/router";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    resolver: yupResolver(loginSchema),
  });
  const { state } = useLocation();

  const onHandleSubmit: SubmitHandler<LoginSchema> = async (formValue) => {
    setIsLoading(true);

    const formBody = new URLSearchParams(formValue);

    try {
      const response = await AuthService.LoginAccount(formValue, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      });

      if (response?.resCode === HttpStatus.OK) {
        setOnStorage("alphacorp::token", response.data?.access_token);
        routes.navigate("/", { replace: true });
        return;
      }

      showError("Invalid login");
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = getValueStorage("alphacorp::token");
    if (token) {
      routes.navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (state?.type && state?.email) {
      if (state?.type === "verify") {
        toast.success(`${state?.email} is verified`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      if (state?.type === "verified") {
        toast.info(`${state?.email} was verified`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
        },
      })}
    >
      <Container
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            gap: 2,
          }}
        >
          <Avatar
            alt="logo"
            src={LogoImg}
            sx={{ width: 80, height: 80 }}
          />
          <Typography
            component="h3"
            variant="h5"
          >
            Welcome Back
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onHandleSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={Boolean(errors.username?.message)}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("username", { required: true })}
              helperText={errors.username?.message}
              sx={{ input: { color: "black" } }}
            />
            <TextField
              error={Boolean(errors.password?.message)}
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", { required: true })}
              helperText={errors.password?.message}
              sx={{ input: { color: "black" } }}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 2 }}
              disableElevation
              loading={isLoading}
            >
              Continue
            </LoadingButton>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
            >
              <Box>Don't have an account?</Box>
              <Link
                to="/auth/sign-up"
                style={{ textDecoration: "none" }}
              >
                <Box sx={{ color: "primary.main", cursor: "pointer" }}>
                  Sign Up
                </Box>
              </Link>
            </Stack>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
