import { LoginAccountType, UserType } from "@/constants/type";
import fetchApi from "@/services/fetchApi";

const endpoint = {
  login: "/login",
  user: "/user",
};

const LoginAccount = (payload: LoginAccountType, options?: RequestInit) =>
  fetchApi(endpoint.login, "POST", payload, options);
const RegisterAccount = (payload: UserType) =>
  fetchApi(endpoint.user, "POST", payload);

export { LoginAccount, RegisterAccount };
