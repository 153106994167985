import { ChatService, UserService } from "@/services/apis";
import { GetSubscription } from "@/services/apis/subscription";
import chatStore from "@/stores/chatStore";
import userStore from "@/stores/userStore";
import { showError } from "@/utils/helper";

const useListRoomChat = () => {
  const { chatRooms, setChatRooms } = chatStore();
  const { profileMe, setProfileMe, subscriptionInfo, setSubscriptionInfo } =
    userStore();

  const fetchRoom = async () => {
    Promise.all([UserService.GetUserMe(), ChatService.GetAllChat()])
      .then((res) => {
        if (res[0]?.resCode === 200) {
          setProfileMe(res[0].data);
        }
        if (res[1]?.resCode === 200) {
          setChatRooms(res[1].data);
        }
      })
      .catch((e) => {
        if (e instanceof Error) {
          showError(e.message);
        }
      });
  };

  const fetchSubscriptionInfo = async () => {
    setSubscriptionInfo(true);

    GetSubscription()
      .then((res) => setSubscriptionInfo(false, res?.data))
      .catch((e) => {
        if (e instanceof Error) {
          showError(e.message);
        }
      });
  };

  return {
    chatRooms,
    profileMe,
    subscriptionInfo,
    fetchRoom,
    fetchSubscriptionInfo,
  };
};

export default useListRoomChat;
