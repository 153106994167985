import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Box,
} from "@mui/material";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { routes } from "@/configs/router";
import { useParams } from "react-router-dom";
import { Chat, GroupedChat } from "@/constants/type";
import { truncate } from "@/utils/helper";

const ListChat = ({
  data,
  onCloseDrawer = () => {},
  msgFromProfile = [],
}: {
  data: Chat[];
  onCloseDrawer: () => void;
  msgFromProfile: Chat[];
}) => {
  const [chats, setChats] = useState<GroupedChat[]>([]);
  const { chatId } = useParams();

  useEffect(() => {
    const groupedMessages: { [key: string]: Chat[] } = {};
    data.forEach((entry) => {
      const createdDate = entry?.created?.split("T")[0];
      if (createdDate) {
        if (!groupedMessages[createdDate]) {
          groupedMessages[createdDate] = [];
        }
        groupedMessages[createdDate].push(entry);
      }
    });

    const reformattedArray: GroupedChat[] = Object.keys(groupedMessages).map(
      (date) => {
        const sortedMessages = groupedMessages[date].sort((a, b) => {
          return (
            new Date(b.created!).getTime() - new Date(a.created!).getTime()
          );
        });

        return {
          createdDate: date,
          messages: sortedMessages,
        };
      }
    );

    setChats(
      reformattedArray.sort((a, b) => {
        const createdDateA = new Date(a.createdDate).getTime();
        const createdDateB = new Date(b.createdDate).getTime();
        return createdDateB - createdDateA;
      })
    );
  }, [data]);

  return (
    <Paper
      style={{
        overflow: "auto",
        height: "100%",
        backgroundColor: "black",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <List
        subheader={<li />}
        sx={{ backgroundColor: "black", minHeight: "100%" }}
      >
        {chats.map((item, index) => (
          <li key={`section-${index}`}>
            <Box component={"ul"} sx={{ padding: 0, mb: 2, height: "100%" }}>
              <ListSubheader
                sx={{
                  backgroundColor: "black",
                  color: "#D0D0D0",
                  fontSize: 12,
                  lineHeight: 3,
                }}
              >
                {moment(item.createdDate).format("MMM Do, YYYY")}
              </ListSubheader>
              {item.messages.map((msg) => {
                const getMsgFromProfile = msgFromProfile.filter(
                  (msgProfile) => msgProfile.id === msg.id
                );
                const content =
                  getMsgFromProfile[0]?.messages?.[0]?.content || "";

                return (
                  <ListItem
                    key={`item-section-${index}`}
                    sx={{
                      padding: 0,
                      "&:hover": { backgroundColor: "#FFFFFF70" },
                    }}
                    onClick={() => {
                      onCloseDrawer();
                      routes.navigate(`/chat/${msg.id}`);
                    }}
                    // secondaryAction={
                    //   <IconButton edge="end" aria-label="action">
                    //     <MoreHorizIcon sx={{ color: "white" }} />
                    //   </IconButton>
                    // }
                  >
                    <ListItemButton
                      sx={{
                        py: 0,
                        borderRadius: 3,
                        "&.Mui-selected": {
                          backgroundColor: "#202123",
                        },
                      }}
                      selected={msg.id === Number(chatId)}
                    >
                      <ListItemText
                        primary={truncate(msg.title || content, 25)}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Box>
          </li>
        ))}
      </List>
    </Paper>
  );
};

export default memo(ListChat);
