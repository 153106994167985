import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "@/assets/ICMainLogo.png";
import DefaultUser from "@/assets/ICDefaultUser.png";
import { Message } from "@/constants/type";
import userStore from "@/stores/userStore";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTour } from "@reactour/tour";

interface IListChatItem {
  chatMessageData: Message;
  handleMessage: (messageId: number, modelId: number) => void;
  simulationModelId: number;
  baseModelId: number;
  handleSimulation: (messageId: number, modelId: number) => void;
  showReplyModel: boolean;
  isAccountPremium?: boolean;
}
const ListChatItem = ({
  chatMessageData,
  handleMessage,
  simulationModelId,
  baseModelId,
  handleSimulation,
  showReplyModel = false,
  isAccountPremium = false,
}: IListChatItem) => {
  const { modelList } = userStore();
  const { setIsOpen, setSteps } = useTour();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSteps?.([
      {
        selector: "textarea#main-chat",
        content: "Continue Chatting",
      },
      {
        selector: ".user-chat",
        content:
          "The 'Ask Another AI' feature enables you to get multiple responses to a single query by clicking a button above your last message (visible upon hovering) to switch models for additional perspectives.",
      },
      {
        selector: "button#simulation",
        content:
          "The 'Reply with AI' button activates a Model-to-Model Chat Simulation, allowing two AI models to converse. Click the button beneath the last message and select another model to start an exchange of responses, with the option to continue the cycle for an evolving discussion.",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getTour = localStorage.getItem("tour");
    if (showReplyModel) {
      if (!Boolean(getTour)) {
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReplyModel]);

  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        "&.MuiStack-root:hover .btn-ask": {
          display: "flex",
        },
        "&.MuiStack-root .btn-ask[aria-expanded='true']": {
          display: "flex",
        },
        width: "100%",
      }}
      className={chatMessageData?.user_id ? "user-chat" : ""}
    >
      <Avatar
        src={chatMessageData?.user_id ? DefaultUser : Logo}
        sx={{ width: 25, height: 25 }}
      />
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
        >
          <b>
            {chatMessageData?.user_id
              ? "You"
              : modelList.find(
                  (item) => item.id === chatMessageData.chat_model_id
                )?.name}
          </b>
          {chatMessageData.user_id && (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  padding: 0,
                  paddingX: 1,
                  marginX: 1,
                  fontSize: 12,
                  display: "flex",
                }}
                className="btn-ask"
                key={chatMessageData.id}
              >
                Ask Another AI
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {modelList.map((model) => (
                  <MenuItem
                    key={model.id}
                    sx={{
                      "&:hover": { backgroundColor: "lightblue" },
                      width: "100%",
                    }}
                    onClick={() => {
                      handleMessage(chatMessageData.id!, model.id);
                      handleClose();
                    }}
                  >
                    {model.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Stack>
        <Typography
          variant="caption"
          sx={{ fontFamily: "Roboto", fontSize: "14px" }}
        >
          <Markdown
            components={{
              code(props) {
                const { children, className, node, ref, ...rest } = props;
                const match = /language-(\w+)/.exec(className || "");
                return match ? (
                  <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={vscDarkPlus}
                  />
                ) : (
                  <code
                    {...rest}
                    className={className}
                  >
                    {children}
                  </code>
                );
              },
            }}
          >
            {chatMessageData.content}
          </Markdown>
        </Typography>
        {showReplyModel && (
          <Box
            width={"100%"}
            justifyContent={"flex-start"}
          >
            <Button
              className="simulation"
              id="simulation"
              variant="contained"
              sx={{
                padding: 0,
                paddingX: 1,
                marginX: 0,
                fontSize: 12,
                display: "flex",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(e) =>
                simulationModelId
                  ? handleSimulation(
                      chatMessageData.id!,
                      simulationModelId === chatMessageData.chat_model_id
                        ? baseModelId
                        : chatMessageData.chat_model_id!
                    )
                  : handleClick(e)
              }
            >
              {simulationModelId
                ? `Reply with ${
                    modelList.find(
                      (model) =>
                        model.id ===
                        (simulationModelId === chatMessageData.chat_model_id
                          ? baseModelId
                          : simulationModelId)
                    )?.name || ""
                  }`
                : `Reply with another AI`}
            </Button>
            <Menu
              id="simulation"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {modelList.map((model) => (
                <MenuItem
                  key={`menu-simulation-${model.id}`}
                  sx={{ "&:hover": { backgroundColor: "lightblue" } }}
                  onClick={() => {
                    handleSimulation(chatMessageData.id!, model.id);
                    handleClose();
                  }}
                >
                  {model.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default ListChatItem;
