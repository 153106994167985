import { Box, Button } from "@mui/material";
import FastForward from "@mui/icons-material/FastForward";

interface IContinueGenerateButton {
  onHandleGenerate: () => void;
}

const ContinueGenerateButton = ({
  onHandleGenerate,
}: IContinueGenerateButton) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        variant="outlined"
        startIcon={<FastForward />}
        onClick={onHandleGenerate}
      >
        Continue Generating
      </Button>
    </Box>
  );
};

export default ContinueGenerateButton;
