import {
  ModelType,
  ProfileMeType,
  SubscriptionInfoType,
} from "@/constants/type";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface IUserStore {
  modelList: ModelType[];
  selectedModel?: ModelType | null;
  profileMe?: ProfileMeType | null;
  subscriptionInfo: { isLoading: boolean; info: SubscriptionInfoType | null };
  setSelectedModel: (model: ModelType | null) => void;
  setProfileMe: (profileMe: ProfileMeType) => void;
  setModelList: (modelList: ModelType[]) => void;
  setSubscriptionInfo: (
    isLoading: boolean,
    subscriptionInfo?: SubscriptionInfoType | null
  ) => void;
}

const userStore = create<IUserStore>()(
  devtools(
    (set) => ({
      selectedModel: null,
      profileMe: null,
      modelList: [],
      subscriptionInfo: { isLoading: false, info: null },
      setSelectedModel: (model: ModelType | null) =>
        set({ selectedModel: model }),
      setProfileMe: (profileMe: ProfileMeType) => set({ profileMe }),
      setModelList: (modelList: ModelType[]) => set({ modelList }),
      setSubscriptionInfo: (
        isLoading: boolean,
        subscriptionInfo?: SubscriptionInfoType | null
      ) =>
        set((state) => ({
          // stores previous state until the new one is loaded
          subscriptionInfo: {
            isLoading,
            info:
              typeof subscriptionInfo === "undefined"
                ? state.subscriptionInfo.info
                : subscriptionInfo,
          },
        })),
    }),
    { name: "userStore" }
  )
);

if (process.env.MODE === "development") {
  mountStoreDevtool("User Store", userStore);
}

export default userStore;
