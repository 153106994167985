import { toast } from "react-toastify";

export const truncate = (input: string = "", lengthCut: number = 10) =>
  input.length > lengthCut ? `${input.substring(0, lengthCut)}...` : input;

export const showError = (message: string) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};
