export const AUTH_TYPE = {
  SIGN_IN: "signin",
  SIGN_UP: "signup",
};

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  UNPROCESSABLE_ENTITY = 422,
  UNAUTHORIZED = 403,
}
