import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { forwardRef, useImperativeHandle } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Check } from "@mui/icons-material";
import subscriptionDescription from "@/data/subscription.json";
import { ProfileMeType, SubscriptionInfoType } from "@/constants/type";
import chatStore from "@/stores/chatStore";
import { CreateSubscription } from "@/services/apis/subscription";
import { showError } from "@/utils/helper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export type ModalSubscriptionRef = {
  closeModal: () => void;
  openModal: () => void;
};

interface ModalSubscriptionProps {
  profileData: ProfileMeType;
  subscriptionInfo?: SubscriptionInfoType | null;
}

const ModalSubscription = forwardRef(
  ({ profileData, subscriptionInfo }: ModalSubscriptionProps, ref) => {
    const { showModalSubscription, setShowModalSubscription } = chatStore();

    useImperativeHandle(ref, () => ({
      openModal: () => setShowModalSubscription(true),
      closeModal: () => setShowModalSubscription(false),
    }));

    const createUrlSubscription = (type: string) => {
      CreateSubscription(type)
        .then((res) => {
          if (res?.resCode === 201) {
            window.location.href = res?.data.url;
          }
        })
        .catch((err) => showError(err));
    };

    return (
      <BootstrapDialog
        open={showModalSubscription}
        onClose={() => setShowModalSubscription(false)}
        aria-labelledby="modal-subscription"
        maxWidth="xl"
      >
        <DialogTitle>
          <Typography
            variant="h6"
            align="center"
          >
            Subscribe to AlphaCorp
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowModalSubscription(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            container
            spacing={2}
            sx={{ minHeight: "500px" }}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Stack spacing={1}>
                <Typography variant="h6">AlphaCorp Lite</Typography>
                <Typography variant="subtitle1">USD $25/Month</Typography>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#81C8EA", // Light blue color for the button
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#70B6D9", // A slightly darker shade on hover for a subtle effect
                    },
                  }}
                  disabled={profileData?.is_subscriber}
                  onClick={() => createUrlSubscription("lite")}
                >
                  {profileData?.is_subscriber
                    ? subscriptionInfo?.subscription_type === "lite"
                      ? "Your Current Plan"
                      : "Start Chatting"
                    : "Start Chatting"}
                </Button>
                <Typography variant="body2">
                  All features for a Month
                </Typography>
                <List>
                  {subscriptionDescription.subscriptions[0].list.map((item) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Stack spacing={1}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Typography variant="h6">AlphaCorp Premium</Typography>
                </Stack>
                <Typography variant="subtitle1">USD $50/Month</Typography>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#81C8EA", // Light blue color for the button
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#70B6D9", // A slightly darker shade on hover for a subtle effect
                    },
                  }}
                  disabled={
                    profileData?.is_subscriber &&
                    subscriptionInfo?.subscription_type === "premium"
                  }
                  onClick={() => createUrlSubscription("premium")}
                >
                  {profileData?.is_subscriber
                    ? subscriptionInfo?.subscription_type === "premium"
                      ? "Your Current Plan"
                      : "Start Chatting"
                    : "Start Chatting"}
                </Button>

                <Typography variant="body2">All features for a Year</Typography>
                <List>
                  {subscriptionDescription.subscriptions[1].list.map((item) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Typography variant="caption">
            AlphaCorp is currently in beta and is continuously evolving with
            regular updates and improvements.
          </Typography>
        </DialogActions>
      </BootstrapDialog>
    );
  }
);

export default ModalSubscription;
