import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TourProvider, StepType } from "@reactour/tour";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-HYHND4ET07";

ReactGA.initialize(TRACKING_ID);
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const steps: StepType[] = [];

root.render(
  <TourProvider
    steps={steps}
    styles={{
      popover: (base) => ({ ...base, color: "black", borderRadius: 5 }),
    }}
    badgeContent={({ totalSteps, currentStep }) =>
      currentStep + 1 + "/" + totalSteps
    }
    onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
      if (steps) {
        if (currentStep === steps.length - 1) {
          setIsOpen(false);
        }
        setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
      }
    }}
    showCloseButton={false}
  >
    <App />
  </TourProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
