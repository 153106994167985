import { IllustrationNotFound } from "@douyinfe/semi-illustrations";
import { Container, Stack, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Container component="main" maxWidth="xl">
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
        spacing={5}
      >
        <IllustrationNotFound />
        <Typography variant="h3">Page not found</Typography>
      </Stack>
    </Container>
  );
};

export default NotFound;
