import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  RadioButtonUnchecked,
  CheckCircle,
} from "@mui/icons-material";
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';

import chatStore from "@/stores/chatStore";
import { useLocation } from "react-router-dom";

interface IInputSelect {
  data: any[];
  selectedData: any;
  onChangeSelected: (value: any) => void;
  disabled?: boolean;
}

const InputSelect = ({
  data = [],
  selectedData,
  onChangeSelected,
  disabled = false,
}: IInputSelect) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState(false);
  const location = useLocation();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelected(true);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedId: number
  ) => {
    onChangeSelected(selectedId);
    setAnchorEl(null);
    setSelected(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  return (
    <div>
      <ToggleButton
        value={"ai-model"}
        selected={selected}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickListItem}
        sx={{
          borderWidth: 0,
          minWidth: 200,
          justifyContent: "space-between",
          height: "40px",
        }}
      >
        <Box component={"p"} sx={{ color: "white", textAlign: "left" }}>
          {selectedData
            ? data.find((item) => item.id === selectedData)?.name
            : ""}
        </Box>
        <ExpandMoreIcon sx={{ color: "white" }} />
      </ToggleButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={item?.id}
            selected={item?.id === selectedData}
            onClick={(event) => handleMenuItemClick(event, item?.id)}
            sx={{
              minWidth: 250,
            }}
            disabled={item.is_disabled && location.pathname.includes("/chat")}
          >
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              direction={"row"}
            >
              <Stack
                alignItems={"center"}
                spacing={1}
                direction={"row"}
                width={"100%"}
              >
                {
                  item.level === 3 ? (
                    <Looks3RoundedIcon sx={{ color: "#00BFFF", fontSize: "33px"}} />
                  ) : item.level === 2 ? (
                    <LooksTwoRoundedIcon sx={{ color: "#00BFFF", fontSize: "33px" }} />
                  ) : item.level === 1 ? (
                    <LooksOneRoundedIcon sx={{ color: "#00BFFF", fontSize: "33px" }} />
                  ) : null
                }
                <Stack spacing={1} direction={"column"} width={"100%"}>
                  <Box component={"p"}>{item?.name}</Box>
                  <Typography variant="caption">
                    {item?.company_name}
                  </Typography>
                </Stack>
              </Stack>
              <Checkbox
                icon={<RadioButtonUnchecked sx={{ color: "white" }} />}
                checkedIcon={<CheckCircle sx={{ color: "white" }} />}
                checked={item?.id === selectedData}
              />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default InputSelect;
