import { Avatar, Box, Stack, Typography } from "@mui/material";
import Logo from "@/assets/ICMainLogo.png";
import ChatInput from "@/components/ChatInput";
import useWindowDimension from "@/hoc/useWindowDimension";
import userStore from "@/stores/userStore";
import { useEffect, useState } from "react";
import { ChatService } from "@/services/apis";
import { ResponseNewChat } from "@/constants/type";
import "@/styles/loading.css";
import useListRoomChat from "@/hooks/useListRoomChat";
import { showError } from "@/utils/helper";
import { useTour } from "@reactour/tour";
import { routes } from "@/configs/router";

const Main = () => {
  const { width } = useWindowDimension();
  const { selectedModel, setSelectedModel, modelList, profileMe } = userStore();
  const [generating, setGenerating] = useState(false);
  const { fetchRoom } = useListRoomChat();
  const { setSteps, setIsOpen } = useTour();

  const handleNewMessage = (content: string) => {
    setGenerating(true);
    ChatService.StartNewChat()
      .then((res) => {
        const chatRes: ResponseNewChat = res?.data;
        if (res?.resCode === 201) {
          fetchRoom().then(() =>
            routes.navigate(`/chat/${chatRes.id}`, { state: { content } })
          );
        }
      })
      .catch((e) => showError(e));
  };

  useEffect(() => {
    setSelectedModel(modelList[0]);
    const getTour = localStorage.getItem("tour");
    if (modelList.length > 0) {
      setSteps?.([
        {
          selector: "textarea#main-chat",
          content: "Group Chat with AI's Here",
        },
        {
          selector: "button#composition-button",
          content: "Multiple Chat Models",
        },
      ]);
      if (!Boolean(getTour)) {
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList]);

  useEffect(() => {
    setSelectedModel(modelList[0]);
    // No dependency array needed here if you always want to set it to the first model initially
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: 1,
        alignItems: "center",
        position: "relative",
        justifyContent: "space-around",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <Stack
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100% - 100px)"}
        spacing={5}
      >
        <Avatar
          src={Logo}
          sx={{ width: 80, height: 80 }}
        />
        <Typography
          variant="h5"
          sx={{ color: "white" }}
        >
          Group Chat with AIs
        </Typography>
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          position: "relative",
          textAlign: "center",
          width: width <= 1366 ? "100%" : "75%",
          mb: 2,
        }}
        spacing={2}
      >
        {generating && (
          <Box
            sx={{
              backgroundColor: "transparent",
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Box>
        )}
        <ChatInput
          id="main-chat"
          style={{
            borderColor: "#FFFFFF50",
            backgroundColor: "transparent",
            color: "white",
            borderRadius: "10px",
            justifyContent: "center",
            borderWidth: 1,
            width: "100%",
          }}
          placeholder="Message AlphaCorp AI..."
          onEnter={(val) => handleNewMessage(val)}
          isDisabled={generating}
        />
        <Typography
          variant="caption"
          sx={{ color: "white" }}
        >
          {`${
            selectedModel?.name || ""
          } can make mistakes. Consider checking important information.`}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Main;
