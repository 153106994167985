import ChatInput from "@/components/ChatInput";
import { Chat as ChatType, Message, MessageChunk } from "@/constants/type";
import useWindowDimension from "@/hoc/useWindowDimension";
import ListChatItem from "@/pages/Chat/ListChatItem";
import { ChatService } from "@/services/apis";
import userStore from "@/stores/userStore";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./styles.css";
import "@/styles/loading.css";
import { showError } from "@/utils/helper";
import { useTour } from "@reactour/tour";
import chatStore from "@/stores/chatStore";
import ContinueGenerateButton from "@/components/ContinueGenerateBtn";
import { HttpStatus } from "@/constants/enums";

const Chat = () => {
  const { width } = useWindowDimension();
  const { chatId } = useParams();
  const [sending, setSending] = useState(false);
  const { selectedModel, profileMe, modelList, setSelectedModel } = userStore();
  const [chatMessage, setChatMessage] = useState<ChatType>({
    id: Number(chatId),
    user_id: 0,
    messages: [],
  });
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const { currentStep, steps } = useTour();
  const { setShowModalSubscription } = chatStore();
  const [showContinueGeneratingBtn, setShowContinueGeneratingBtn] =
    useState(false);
  const { state } = useLocation();

  const handleGetListMsg = () => {
    ChatService.GetSingleChat(Number(chatId))
      .then((res) => {
        if (res?.resCode === 200) {
          setChatMessage({
            ...res.data,
            base_chat_model_id:
              res.data.base_chat_model_id ?? selectedModel?.id,
          });

          setShowContinueGeneratingBtn(
            res.data.messages.length &&
              res.data?.messages[res.data?.messages.length - 1].is_unfinished
          );
        }
      })
      .catch((e) => {
        showError("Error getting list message");
      });
  };

  const handleAppendMsg = (msg: Message | Message[]) => {
    setChatMessage((prev) => ({
      ...prev,
      messages: [
        ...(prev.messages ?? []),
        ...(Array.isArray(msg) ? msg : [msg]),
      ],
    }));
  };

  const handleLastMessage = (message: string, id?: number) => {
    setChatMessage((msg) => {
      const newData = [...(msg.messages || [])];
      if (newData.length > 0) {
        const lastItem = newData[newData.length - 1];

        lastItem.id ??= id;
        lastItem.content = lastItem.content.concat(message);
      }

      return {
        ...msg,
        messages: newData,
      };
    });
  };

  const fetchStream = async (
    content?: string,
    isWebSearch?: boolean,
    isFromModel: boolean = false,
    isFirstChat: boolean = false,
  ) => {
    setSending(true);
    let userMessageIndex = Number(chatMessage.messages?.length);
    let userMessageId: number | null = null;
  
    if (isFirstChat) {
      handleAppendMsg([
        {
          id: null,
          chat_model_id: isFromModel ? selectedModel?.id! : null,
          content: content || "",
          user_id: profileMe?.id,
        },
        {
          chat_model_id: selectedModel?.id!,
          content: "",
          user_id: null,
        },
      ]);
    } else {
      handleAppendMsg({
        id: null,
        chat_model_id: isFromModel ? selectedModel?.id! : null,
        content: content || "",
        user_id: profileMe?.id,
      });
    }
  
    await ChatService.SendMessageStream(
      Number(chatId),
      {
        chat_model_id: selectedModel?.id,
        content,
        is_web_search: isWebSearch
      },
      {
        onopen: async (res) => {
          if (res.status === HttpStatus.OK && !isFirstChat) {
            handleAppendMsg({
              id: -1,
              chat_model_id: selectedModel?.id!,
              content: "",
              user_id: profileMe?.id,
            });
          }
  
          if (res.status === HttpStatus.UNAUTHORIZED) {
            setShowModalSubscription(true);
          }
        },
        onerror: (e) => {
          if (!!e) {
            showError(e);
          }
          throw e;
        },
        onmessage: async (ev) => {
          const data = JSON.parse(ev.data) as MessageChunk.SendMessage;
  
          if (!data) {
            return;
          }
  
          userMessageId = data.user_message_id;
          handleLastMessage(data.message, data.model_message_id);
        },
        onclose: () => {
          setSending(false);
  
          // updating user sent message id
          setChatMessage((chatMessage) => ({
            ...chatMessage,
            messages: chatMessage.messages?.map((message, index) => {
              if (index === userMessageIndex) {
                return { ...message, id: userMessageId };
              }
  
              return message;
            }),
          }));
          window.history.replaceState({}, "");
        },
      }
    );
  };

  const handleAskAnotherModel = (messageId: number, modelId: number) => {
    setSending(true);
    ChatService.ReplyModalStream(
      Number(messageId),
      {
        chat_model_id: modelId,
      },
      {
        onopen: async (res) => {
          const contentType = res.headers.get("content-type");
          if (res.status === 200) {
            handleAppendMsg({
              id: null,
              chat_model_id: modelId,
              content: "",
              user_id: null,
            });
          }
          if (res.status === 403) {
            setShowModalSubscription(true);
          }
          if (!!contentType && contentType.indexOf("application/json") >= 0) {
            throw await res.json();
          }
        },
        onerror: (e) => {
          showError(e);
          throw e;
        },
        onmessage: async (ev) => {
          const data = JSON.parse(ev.data) as MessageChunk.AnotherModelReply;

          if (!data) {
            return;
          }

          handleLastMessage(data.message, data.model_message_id);
        },
        onclose: () => {
          setSending(false);
        },
      }
    );
  };

  const handleSimulation = (messageId: number, modelId: number) => {
    setSending(true);
    ChatService.ChatSimulationStream(
      Number(messageId),
      {
        chat_model_id: modelId,
      },
      {
        onopen: async (res) => {
          const contentType = res.headers.get("content-type");
          if (res.status === 200) {
            handleAppendMsg({
              id: null,
              chat_model_id: modelId,
              content: "",
              user_id: null,
            });
          }
          if (res.status === 403) {
            setShowModalSubscription(true);
          }
          if (!!contentType && contentType.indexOf("application/json") >= 0) {
            throw await res.json();
          }
        },
        onerror: (e) => {
          showError(e);
          throw e;
        },
        onmessage: async (ev) => {
          const data = JSON.parse(ev.data) as MessageChunk.AnotherModelReply;

          if (!data) {
            return;
          }

          handleLastMessage(data.message, data.model_message_id);
        },
        onclose: () => {
          ChatService.GetSingleChat(Number(chatId))
            .then((res) => {
              if (res?.resCode === 200) {
                setChatMessage((chatMessage) => ({
                  ...res.data,
                  messages: chatMessage.messages,
                  base_chat_model_id:
                    res.data.base_chat_model_id ?? selectedModel?.id,
                }));
              }
            })
            .catch((e) => {
              showError("Error getting chat data");
            })
            .finally(() => {
              setSending(false);
            });
        },
      }
    );
  };

  const handleContinueGenerating = () => {
    setSending(true);
    setShowContinueGeneratingBtn(false);
    if (
      chatMessage.messages?.length &&
      chatMessage.messages[chatMessage.messages.length - 1].is_unfinished
    ) {
      const lastMessage =
        chatMessage?.messages[chatMessage.messages?.length - 1];
      ChatService.CompleteMessageStream(lastMessage.id!, {
        onopen: async (res) => {
          const contentType = res.headers.get("content-type");
          if (res.status === 200) {
            handleAppendMsg({
              id: null,
              chat_model_id: lastMessage.chat_model_id,
              content: "",
              user_id: null,
            });
          }
          if (res.status === 403) {
            setShowModalSubscription(true);
          }
          if (!!contentType && contentType.indexOf("application/json") >= 0) {
            throw await res.json();
          }
        },
        onerror: (e) => {
          if (!!e) {
            showError(e);
          }
          throw e;
        },
        onmessage: async (ev) => {
          const data = JSON.parse(ev.data) as MessageChunk.ContinueGenerating;

          if (!data) {
            return;
          }

          handleLastMessage(data.message);
        },
        onclose: () => {
          setSending(false);
        },
      });
    }
  };

  useEffect(() => {
    chatRoomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessage.messages]);

  useEffect(() => {
    if (chatMessage?.messages?.length! > 0) {
      setSelectedModel(
        modelList.find(
          (model) => model.id === chatMessage?.messages?.[1]?.chat_model_id
        )!
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessage, modelList]);

  useEffect(() => {
    if (steps.length - 1 === currentStep) {
      localStorage.setItem("tour", JSON.stringify(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (state?.content) {
      fetchStream(state.content, false, false, true);
    } else {
      handleGetListMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, chatId]);

  return (
    <Box
      sx={{
        height: "100%",
        width: 1,
        alignItems: "center",
        position: "relative",
        justifyContent: "space-between",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "calc(100% - 100px)",
          overflow: "auto",
        }}
      >
        <List sx={{ width: width <= 1366 ? "100%" : "75%" }}>
          <TransitionGroup>
            {chatMessage?.messages?.map((msg, index) => (
              <CSSTransition
                key={index}
                timeout={500}
                classNames={"item"}
              >
                <ListItem>
                  <ListChatItem
                    handleMessage={handleAskAnotherModel}
                    chatMessageData={msg}
                    simulationModelId={
                      chatMessage.simulation_chat_model_id ?? 0
                    }
                    baseModelId={chatMessage.base_chat_model_id ?? 0}
                    handleSimulation={handleSimulation}
                    showReplyModel={
                      index === chatMessage?.messages?.length! - 1 &&
                      Boolean(
                        chatMessage?.messages?.[
                          chatMessage?.messages.length - 1
                        ].chat_model_id
                      )
                    }
                    isAccountPremium={profileMe?.is_subscriber}
                  />
                </ListItem>
              </CSSTransition>
            ))}
            <div ref={chatRoomRef} />
          </TransitionGroup>
        </List>
      </Box>
      <Stack
        direction={"column"}
        sx={{
          position: "relative",
          textAlign: "center",
          width: width <= 1366 ? "100%" : "75%",
          mb: 2,
        }}
        spacing={2}
      >
        {sending && (
          <Box
            sx={{
              backgroundColor: "transparent",
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Box>
        )}
        {showContinueGeneratingBtn && (
          <ContinueGenerateButton onHandleGenerate={handleContinueGenerating} />
        )}
        <ChatInput
          id="main-chat"
          style={{
            borderColor: "#FFFFFF50",
            backgroundColor: "transparent",
            color: "white",
            borderRadius: "10px",
            justifyContent: "center",
            borderWidth: 1,
            width: "100%",
          }}
          placeholder="Message AlphaCorp AI..."
          onEnter={(value, isWebSearch) => fetchStream(value, isWebSearch)}
          isDisabled={sending}
        />
        <Typography
          variant="caption"
          sx={{ color: "white" }}
        >
          {`${selectedModel?.name} can make mistakes. Consider checking important information.`}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Chat;
