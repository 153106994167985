export const getValueStorage = (key: string) => {
  const getValue = localStorage.getItem(key);

  if (getValue) {
    return JSON.parse(getValue);
  } else {
    return null;
  }
};

export const setOnStorage = (key: string, value: any) => {
  const stringifyValue = JSON.stringify(value);

  localStorage.setItem(key, stringifyValue);
};

export const deleteOnStorage = (key: string) => {
  localStorage.removeItem(key);
};
