import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  username: Yup.string().email().required("Email address required"),
  password: Yup.string().required("Password required"),
});

export const signUpSchema = Yup.object().shape({
  username: Yup.string().email().required("Email address required"),
  password: Yup.string()
    .required("Password required")
    .matches(
      /^.{8,}$/,
      "Password must be at least 8 characters long."
    ),
  confirmationPassword: Yup.string()
    .required("Password confirmation field required")
    .equals([Yup.ref("password")], "Passwords do not match"),
  termsOfAgreement: Yup.boolean().isTrue(
    "You must be familiarized with the terms"
  ),
});

export type LoginSchema = Yup.InferType<typeof loginSchema>;
export type SignUpSchema = Yup.InferType<typeof signUpSchema>;
