import { PayloadNewChatMessage } from "@/constants/type";
import fetchApi from "@/services/fetchApi";
import fetchStream from "@/services/stream";
import { FetchEventSourceInit } from "@microsoft/fetch-event-source";

const endpoint = {
  chat: "/chat",
  chatMessage: "/chat-message",
  chatStreamMsg: "/chat-message-stream",
};

export const GetAllChat = () => fetchApi(endpoint.chat, "GET");
export const GetSingleChat = (
  chatId: number,
  excludeMessages: boolean = false
) => {
  const params = new URLSearchParams({
    exclude_messages: excludeMessages ? "true" : "false",
  });

  const url = `${endpoint.chat}/${chatId}?${params}`;
  return fetchApi(url, "GET");
};

export const StartNewChat = () => fetchApi(endpoint.chat, "POST");
export const SendNewMessage = (
  payload: PayloadNewChatMessage,
  chatId: number,
  additionalFunction?: () => void
) =>
  fetchApi(
    `${endpoint.chatMessage}/${chatId}/message`,
    "POST",
    payload,
    {},
    additionalFunction
  );
export const GetReplayMessage = (
  chatModelId: number,
  messageId: number,
  additionalFunction?: () => void
) =>
  fetchApi(
    `${endpoint.chatMessage}/${messageId}/model-reply`,
    "POST",
    {
      chat_model_id: chatModelId,
    },
    {},
    additionalFunction
  );
export const SimulationMessage = (
  msgId: number,
  payload: { chat_model_id: number; instruction?: string },
  additionalFunction: () => void
) =>
  fetchApi(
    `${endpoint.chatMessage}/${msgId}/simulation`,
    "POST",
    payload,
    {},
    additionalFunction
  );

export const ContinueGenerateMessage = (msgId: number) =>
  fetchApi(`${endpoint.chatMessage}/${msgId}/get-completion`, "POST");

export const SendMessageStream = async (
  chatId: number,
  payload: any,
  anyOption: FetchEventSourceInit
) =>
  await fetchStream(
    `${endpoint.chatStreamMsg}/${chatId}/message`,
    "POST",
    payload,
    anyOption
  );

export const ReplyModalStream = async (
  msgId: number,
  payload: { chat_model_id: number },
  anyOption: FetchEventSourceInit
) =>
  await fetchStream(
    `${endpoint.chatStreamMsg}/${msgId}/model-reply`,
    "POST",
    payload,
    anyOption
  );

export const ChatSimulationStream = async (
  msgId: number,
  payload: { chat_model_id: number; instruction?: string },
  anyOption: FetchEventSourceInit
) =>
  await fetchStream(
    `${endpoint.chatStreamMsg}/${msgId}/simulation`,
    "POST",
    payload,
    anyOption
  );

export const CompleteMessageStream = async (
  msgId: number,
  anyOption: FetchEventSourceInit
) =>
  await fetchStream(
    `${endpoint.chatStreamMsg}/${msgId}/get-completion`,
    "POST",
    null,
    anyOption
  );
