import { createTheme } from "@mui/material";

const primaryTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "black",
          color: "white",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#202123",
          color: "white",
          borderColor: "white",
          borderWidth: 1,
        },
      },
    },
  },
  palette: {
    text: {
      primary: "#FFFFFF",
    },
    mode: "dark",
  },
});

export default primaryTheme;
