import Layout from "@/components/Layout";
import Login from "@/pages/Auth/Login";
import SignUp from "@/pages/Auth/SignUp";
import Chat from "@/pages/Chat";
import Main from "@/pages/Main";
import NotFound from "@/pages/NotFound";
import Verify from "@/pages/Verify";
import { createBrowserRouter } from "react-router-dom";

export const routes = createBrowserRouter([
  {
    path: "/auth",
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/sign-up",
        element: <SignUp />,
      },
    ],
  },
  {
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/chat/:chatId",
        element: <Chat />,
      },
    ],
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/verified",
    element: <Verify />,
  },
]);
