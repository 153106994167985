import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  CssBaseline,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import LogoImg from "@/assets/ICMainLogo.png";
import withProtectedRoute from "@/hoc/withProtectedRoute";
import { Outlet, useLocation } from "react-router-dom";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import InputSelect from "@/components/InputSelect";
import { useEffect, useMemo, useRef, useState } from "react";
import userStore from "@/stores/userStore";
import { ModelService } from "@/services/apis";
import BottomProfile from "@/components/Layout/BottomProfile";
import LoadingOverlay from "@/components/LoadingOverlay";
import ListChat from "@/components/Layout/ListChat";
import { routes } from "@/configs/router";
import SegmentIcon from "@mui/icons-material/Segment";
import useWindowDimension from "@/hoc/useWindowDimension";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import useListRoomChat from "@/hooks/useListRoomChat";
import { ModelType } from "@/constants/type";
import ModalSubscription, { ModalSubscriptionRef } from "../ModalSubscription";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobile ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  paddingTop: "64px",
  backgroundColor: "#343541",
  height: "100vh",
  overflow: "hidden",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMobile?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, isMobile }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Layout = () => {
  const { setSelectedModel, selectedModel, modelList, setModelList } =
    userStore();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const { width } = useWindowDimension();
  const {
    chatRooms,
    fetchRoom,
    profileMe,
    fetchSubscriptionInfo,
    subscriptionInfo,
  } = useListRoomChat();
  const isMobile = useMemo(() => width <= 800, [width]);
  const modalSubscriptionRef = useRef<ModalSubscriptionRef>(null);
  const location = useLocation();

  const fetchAll = (isAllDisable: boolean = false) => {
    setIsLoading(true);
    Promise.all([ModelService.GetAllModel(), fetchRoom()])
      .then((res) => {
        if (res[0]?.resCode === 200) {
          setModelList(
            res[0].data.map((model: ModelType) => ({
              ...model,
              is_disabled: isAllDisable
                ? true
                : profileMe?.is_subscriber !== model.is_premium,
            }))
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchAll();
    if (location.pathname.includes("chat")) {
      fetchAll(true);
    }
    if (profileMe?.is_subscriber) {
      fetchSubscriptionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileMe?.is_subscriber, location]);

  useEffect(() => {
    if (width <= 800) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);

  return (
    <>
      <LoadingOverlay show={isLoading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "transparent",
          }}
          elevation={0}
          open={open}
          isMobile={isMobile}
        >
          <Toolbar>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              {isMobile && (
                <IconButton onClick={() => setOpen((prev) => !prev)}>
                  <SegmentIcon sx={{ color: "white" }} />
                </IconButton>
              )}
              <InputSelect
                data={modelList}
                selectedData={selectedModel?.id}
                onChangeSelected={(val) =>
                  setSelectedModel(
                    modelList.find((item) => item.id === val) || null
                  )
                }
              />
            </Stack>
          </Toolbar>
        </AppBar>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => open && isMobile && setOpen(false)}
        >
          <Drawer
            sx={{
              ...(!isMobile && {
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  overflowY: "hidden",
                },
              }),
            }}
            variant={isMobile ? "persistent" : "permanent"}
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
          >
            <DrawerHeader
              sx={{
                px: 0,
                "&.MuiToolbar-root": {
                  padding: 0,
                  px: 1,
                },
              }}
            >
              <Button
                fullWidth
                variant="text"
                onClick={() => {
                  isMobile && setOpen(false);
                  routes.navigate("/");
                  setModelList(
                    modelList.map((model) => ({
                      ...model,
                      is_disabled: false,
                    }))
                  );
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width={"100%"}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Avatar
                      alt="logo"
                      src={LogoImg}
                      sx={{ width: 30, height: 30 }}
                    />
                    <Box
                      component={"p"}
                      sx={{ fontSize: 14, color: "white" }}
                    >
                      New Chat
                    </Box>
                  </Stack>
                  <LibraryAddIcon sx={{ color: "white" }} />
                </Stack>
              </Button>
            </DrawerHeader>
            <ListChat
              data={chatRooms || []}
              onCloseDrawer={() => (isMobile ? setOpen(false) : {})}
              msgFromProfile={profileMe?.chats || []}
            />
            <BottomProfile
              profileData={profileMe}
              openModalSubscription={() =>
                modalSubscriptionRef.current?.openModal()
              }
              subscriptionInfo={subscriptionInfo.info}
            />
          </Drawer>
        </ClickAwayListener>
        <Main
          open={open}
          isMobile={isMobile}
        >
          <Outlet />
          <ModalSubscription
            ref={modalSubscriptionRef}
            profileData={profileMe!}
            subscriptionInfo={subscriptionInfo.info}
          />
        </Main>
      </Box>
    </>
  );
};

export default withProtectedRoute(Layout);
