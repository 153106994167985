import { routes } from "@/configs/router";
import { HttpStatus } from "@/constants/enums";
import { METHOD } from "@/constants/type";
import { showError } from "@/utils/helper";
import { deleteOnStorage, getValueStorage } from "@/utils/storage";

interface ApiReturn {
  data?: any | null;
  resCode: HttpStatus;
}

const BASE_URL = 'https://api.alphacorp.ai';

const convertPayloadIntoParams = (endpoint: string, payload: any): string => {
  const params = Object.keys(payload)
    .map((x) => {
      return encodeURIComponent(x) + "=" + encodeURIComponent(payload[x]);
    })
    .join("&");

  return `${BASE_URL}${endpoint}?${params}`;
};

const fetchApi = async (
  endpoint: string,
  method: METHOD,
  payload?: any | null,
  options?: RequestInit,
  additionalFunction?: () => void
): Promise<ApiReturn> => {
  let url: string = `${BASE_URL}${endpoint}`;
  const token = getValueStorage("alphacorp::token");

  try {
    if (method === "GET" && payload) {
      url = convertPayloadIntoParams(endpoint, payload);
    }

    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: method !== "GET" ? JSON.stringify(payload) : undefined,
      ...options,
    });

    const dataObj = await response.json(); // consider defining a more specific type instead of any
    const resCode: number = response.status;

    if (!response.ok) {
      throw new Error(dataObj.detail);
    }

    return {
      data: dataObj,
      resCode,
    };
  } catch (error) {
    if (error instanceof Error) {
      throw error; // Rethrow the error to be handled by the caller
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export default fetchApi;
