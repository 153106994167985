import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";

const DarkBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-gap: 20;

  ${({ show }: { show: boolean }) =>
    show &&
    css`
      display: flex; /* show */
    `};
`;

const LoadingOverlay = ({ show }: { show: boolean }) => (
  <DarkBackground show={show}>
    <CircularProgress sx={{ color: "white" }} />
    <Typography variant="h6">Loading Content</Typography>
  </DarkBackground>
);

export default LoadingOverlay;
