import fetchApi from "@/services/fetchApi";

const endpoint = {
  createSubscription: "/user-subscription/create-subscription",
  userSubscription: "/user-subscription",
  cancelSubscription: "/user-subscription/cancel-subscription",
};

export const CreateSubscription = (type: string) =>
  fetchApi(
    `${endpoint.createSubscription}?subscription_type=${type}`,
    "POST",
    null
  );

export const GetSubscription = () => fetchApi(endpoint.userSubscription, "GET");
export const CancelSubscription = () =>
  fetchApi(endpoint.cancelSubscription, "DELETE");
