import { CancelSubscription } from "@/services/apis/subscription";
import userStore from "@/stores/userStore";
import { showError } from "@/utils/helper";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import moment from "moment";

export interface IModalSubscriptionDetails {
  open: () => void;
  close: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalSubscriptionDetails = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { subscriptionInfo } = userStore();

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    []
  );

  const doCancelSubscription = () => {
    setLoading(true);
    CancelSubscription()
      .then((res) => {
        if (res?.resCode === 200) {
          window.location.reload();
        }
      })
      .catch((e) => showError(e))
      .finally(() => setLoading(false));
  };

  const isSubscriptionCanceled =
    subscriptionInfo.info?.subscription_status === "canceled";

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => (loading ? null : setOpen(false))}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h6">Subscription Details</Typography>
            <TableContainer
              component={Paper}
              sx={{ my: 2, position: "relative" }}
            >
              <Fade
                appear
                in={subscriptionInfo.isLoading}
              >
                <Box
                  position="absolute"
                  display="flex"
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Box>
              </Fade>

              {subscriptionInfo.info ? (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Subscription status</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {subscriptionInfo.info?.subscription_status}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Next billing</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {moment(
                          subscriptionInfo.info?.subscription_period_end
                        ).format("MMM Do, YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Used level 1 chars limit</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {subscriptionInfo.info?.monthly_level_one_chars_used}/
                        {
                          subscriptionInfo.info
                            ?.subscription_level_one_char_limit
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Used level 2 chars limit</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {subscriptionInfo.info?.monthly_level_two_chars_used}/
                        {
                          subscriptionInfo.info
                            ?.subscription_level_two_char_limit
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Used level 3 chars limit</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {subscriptionInfo.info?.monthly_level_three_chars_used}/
                        {
                          subscriptionInfo.info
                            ?.subscription_level_three_char_limit
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  paddingX={3}
                  paddingY={2}
                >
                  No subscription information available
                </Typography>
              )}
            </TableContainer>

            <Stack
              direction={"row"}
              spacing={2}
            >
              <LoadingButton
                type="button"
                variant="contained"
                color="error"
                disabled={loading}
                onClick={() => setOpen(false)}
              >
                Close
              </LoadingButton>
              <LoadingButton
                type="button"
                variant="outlined"
                color="error"
                loading={loading}
                disabled={
                  loading || isSubscriptionCanceled || !subscriptionInfo.info
                }
                onClick={doCancelSubscription}
              >
                Cancel Subscription
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
});

export default ModalSubscriptionDetails;
