import { useEffect, useState } from "react";

export default function useWindowDimension() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    };
  }, []);

  return screenSize;
}
