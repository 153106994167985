import { routes } from "@/configs/router";
import {
  Box,
  CircularProgress,
  Container,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

const Verify = () => {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const type = pathname.replace("/", "");
  const email = params.get("email");

  useEffect(() => {
    setTimeout(() => {
      routes.navigate("/auth/login", { state: { type, email } });
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
        },
      })}
    >
      <Container
        component={"main"}
        maxWidth="lg"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          display: "flex",
          marginTop: 5,
          gap: 1,
        }}
      >
        You are being redirecting to AlphaCorp...
        <CircularProgress />
        <Box>
          if you haven't been redirecting in 5 seconds, please click{" "}
          <Link
            to={"/auth/login"}
            state={{ type, email }}
          >
            here
          </Link>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Verify;
