import { Chat } from "@/constants/type";
import { create } from "zustand";

interface IChatStore {
  chatRooms: Chat[];
  setChatRooms: (chatRooms: Chat[]) => void;
  showModalSubscription: boolean;
  setShowModalSubscription: (isShow: boolean) => void;
}

const chatStore = create<IChatStore>((set) => ({
  chatRooms: [],
  setChatRooms: (chatRooms: Chat[]) =>
    set({
      chatRooms,
    }),
  showModalSubscription: false,
  setShowModalSubscription: (isShow: boolean) =>
    set({ showModalSubscription: isShow }),
}));

export default chatStore;
