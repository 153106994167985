import { METHOD } from "@/constants/type";
import { getValueStorage } from "@/utils/storage";
import {
  FetchEventSourceInit,
  fetchEventSource,
} from "@microsoft/fetch-event-source";

const ctrl = new AbortController();

const BASE_URL = 'https://api.alphacorp.ai';

const fetchStream = async (
  endpoint: string,
  method: METHOD,
  payload: any,
  anyOption?: FetchEventSourceInit
) => {
  const endpointUrl = `${BASE_URL}${endpoint}`;
  const token = getValueStorage("alphacorp::token");

  return fetchEventSource(endpointUrl, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...(payload && { body: JSON.stringify(payload) }),
    signal: ctrl.signal,
    ...anyOption,
  });
};

export default fetchStream;
