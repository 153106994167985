import { HttpStatus } from "@/constants/enums";
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import LogoImg from "@/assets/ICMainLogo.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema, SignUpSchema } from "@/schemas/user";
import { LoadingButton } from "@mui/lab";
import { AuthService } from "@/services/apis";
import { getValueStorage } from "@/utils/storage";
import { showError } from "@/utils/helper";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "@/configs/router";

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpSchema>({
    resolver: yupResolver(signUpSchema),
  });
  const { state } = useLocation();

  const registerAccount: SubmitHandler<SignUpSchema> = (formValue) => {
    setIsLoading(true);

    AuthService.RegisterAccount({
      email: formValue.username,
      password: formValue.password,
    })
    .then((res) => {
      if (res?.resCode === HttpStatus.CREATED) {
        toast.success(
          `Verify your email please. It was sent to ${formValue.username}`
        );
        return;
      }
    
    })
    .catch((e) => {
      showError(e.message);
    })
    .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const token = getValueStorage("alphacorp::token");
    if (token) {
      routes.navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (state?.type && state?.email) {
      if (state?.type === "verify") {
        toast.success(`${state?.email} is verified`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      if (state?.type === "verified") {
        toast.info(`${state?.email} was verified`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
        },
      })}
    >
      <Container
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            gap: 2,
          }}
        >
          <Avatar
            alt="logo"
            src={LogoImg}
            sx={{ width: 80, height: 80 }}
          />
          <Typography
            component="h3"
            variant="h5"
          >
            Create Your Account
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(registerAccount)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={Boolean(errors.username?.message)}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("username", { required: true })}
              helperText={errors.username?.message}
              sx={{ input: { color: "black" } }}
            />
            <TextField
              error={Boolean(errors.password?.message)}
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", { required: true })}
              helperText={errors.password?.message}
              sx={{ input: { color: "black" } }}
            />
            <TextField
              error={Boolean(errors.confirmationPassword?.message)}
              margin="normal"
              required
              fullWidth
              label="Confirm password"
              type="password"
              id="confirm-password"
              autoComplete="current-password"
              {...register("confirmationPassword", { required: true })}
              helperText={errors.confirmationPassword?.message?.toString()}
              sx={{ input: { color: "black" } }}
            />
            <FormControl error={Boolean(errors.termsOfAgreement)}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    id="termsOfAgreement"
                    {...register("termsOfAgreement")}
                  ></Checkbox>
                }
                label={
                  <Typography
                    variant="caption"
                    component="span"
                  >
                    Agree to{" "}
                    <Typography
                      component="a"
                      href="https://alphacorp.ai/terms-and-conditions/"
                      sx={{ color: "primary.main", cursor: "pointer" }}
                      variant="caption"
                    >
                      Terms of Service
                    </Typography>{" "}
                    and{" "}
                    <Typography
                      component="a"
                      href="https://alphacorp.ai/privacy-policy/"
                      sx={{ color: "primary.main", cursor: "pointer" }}
                      variant="caption"
                    >
                      Privacy Policy
                    </Typography>
                  </Typography>
                }
              />
              <FormHelperText>
                {errors.termsOfAgreement?.message}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 2 }}
              disableElevation
              loading={isLoading}
            >
              Continue
            </LoadingButton>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
            >
              <Box>Already have an account?</Box>
              <Link
                to="/auth/login"
                style={{ textDecoration: "none" }}
              >
                <Box sx={{ color: "primary.main", cursor: "pointer" }}>
                  Sign In
                </Box>
              </Link>
            </Stack>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
