import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { MenuProps } from "@mui/material/Menu";
import DefaultUser from "@/assets/ICDefaultUser.png";
import { ProfileMeType, SubscriptionInfoType } from "@/constants/type";
import { useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { styled, alpha, useTheme } from "@mui/material/styles";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { deleteOnStorage } from "@/utils/storage";
import { routes } from "@/configs/router";
import { CardMembership } from "@mui/icons-material";
import ModalSubscriptionDetails, {
  IModalSubscriptionDetails,
} from "@/components/ModalSubscriptionDetails";
import moment from "moment";
import { GetSubscription } from "@/services/apis/subscription";
import userStore from "@/stores/userStore";
import { showError } from "@/utils/helper";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 200,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color: "#FFFFFF",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "white",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BottomProfile = ({
  profileData,
  openModalSubscription,
  subscriptionInfo,
}: {
  profileData?: ProfileMeType | null;
  openModalSubscription: () => void;
  subscriptionInfo?: SubscriptionInfoType | null;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const subscriptionDetailsRef = useRef<IModalSubscriptionDetails>(null);
  const { setSubscriptionInfo } = userStore();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    deleteOnStorage("alphacorp::token");
    routes.navigate("/auth/login");
    handleClose();
  };

  const updateSubscriptionInfo = () => {
    setSubscriptionInfo(true);

    GetSubscription()
      .then((res) => setSubscriptionInfo(false, res?.data))
      .catch((e) => {
        if (e instanceof Error) {
          showError(e.message);
        }
      });
  };

  return (
    <Box
      component={"div"}
      sx={{ width: "100%", flex: 1, flexDirection: "column" }}
    >
      {!(subscriptionInfo?.subscription_type === "premium") && (
        <Button
          sx={{ p: 0, color: "white", textTransform: "none" }}
          fullWidth
        >
          <Stack
            sx={{
              width: "100%",
              flex: 1,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 1,
              mx: "8px",
              "&:hover": {
                backgroundColor: "#FFFFFF60",
              },
              borderRadius: 2,
            }}
            onClick={() => openModalSubscription()}
          >
            <Avatar sx={{ bgcolor: "black", border: "1px solid #FFFFFF50" }}>
              <AutoAwesomeRoundedIcon
                sx={{
                  color: theme.palette.mode === "dark" ? "white" : "black",
                }}
              />
            </Avatar>
            <Stack
              sx={{
                flex: 1,
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                small: {
                  textAlign: "left",
                  fontSize: "10px",
                },
              }}
            >
              <b>Upgrade Plan</b>
              <small>Access AlphaCorp AI Chat</small>
            </Stack>
          </Stack>
        </Button>
      )}
      <Button
        sx={{
          my: 2,
          py: 1,
          flex: 1,
          "&:hover": { backgroundColor: "#FFFFFF70" },
        }}
        fullWidth
        onClick={handleClick}
      >
        <Stack
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"start"}
          width={"100%"}
          mx={1}
        >
          <Avatar
            alt="logo"
            src={profileData?.photos_of_user?.[0]?.url || DefaultUser}
            sx={{ width: 30, height: 30 }}
          />
          <Stack
            direction={"column"}
            display={"grid"}
            spacing={0.1}
          >
            <Box
              component={"p"}
              color={"white"}
              style={{
                textOverflow: "ellipsis",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textTransform: "none",
              }}
            >
              {profileData?.display_name
                ? profileData?.display_name
                : profileData?.email}
            </Box>
            <Box
              component={"p"}
              color={"white"}
              textAlign={"left"}
              fontSize={10}
              textTransform={"capitalize"}
            >
              {profileData?.is_subscriber
                ? subscriptionInfo?.subscription_type
                : "Free Account"}{" "}
              {profileData?.is_subscriber &&
                `(Until: ${
                  subscriptionInfo?.subscription_period_end
                    ? moment(subscriptionInfo?.subscription_period_end).format(
                        "DD MMM YYYY"
                      )
                    : "-"
                })`}
            </Box>
          </Stack>
        </Stack>
      </Button>
      <StyledMenu
        id="profile-menu"
        aria-controls={open ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClose}
        open={open}
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
      >
        {profileData?.is_subscriber && (
          <MenuItem
            onClick={() => {
              updateSubscriptionInfo();
              subscriptionDetailsRef.current?.open();
            }}
          >
            <CardMembership /> Subscription
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <SettingsIcon /> Settings
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon /> Logout
        </MenuItem>
      </StyledMenu>
      <ModalSubscriptionDetails ref={subscriptionDetailsRef} />
    </Box>
  );
};

export default BottomProfile;
